<template>
  <AddItemComponent
    title="棚卸し商品登録"
    :confirmSubmitForm="confirmSubmitForm"
    :goBack="goBack"
    :onDecode="onDecode"
    :onRegisterItem="onRegisterItem"
  >
    <template v-slot:dataTable>
      <div>登録数: {{ itemIds.length }}</div>
      <div>販売価格合計: {{ inventoryItems.reduce((sum, item) => sum + parseInt(item.price.replace(/,/g, '')), 0).toLocaleString() }}</div>
      <CDataTable
        hover
        striped
        border
        :itemsPerPage="200"
        :items="inventoryItems"
        :fields="fields"
      >
        <td slot="cost" slot-scope="{ item }" class="text-right">
          {{ item.cost }}
        </td>
        <td slot="price" slot-scope="{ item }" class="text-right">
          {{ item.price }}
        </td>
        <td slot="retail_price" slot-scope="{ item }" class="text-right">
          {{ item.retail_price }}
        </td>
        <td slot="delete" slot-scope="{ item }">
          <CButtonClose @click="handleDelete(item)" />
        </td>
      </CDataTable>
    </template>
  </AddItemComponent>
</template>

<script>
import { numberWithCommas } from "@/utils/utils";
import ModalComponent from "@/components/views/ModalComponent";
import AddItemComponent from "@/components/views/AddItemComponent";
import InventoryItems from "@/views/inventory-works/InventoryItemFields";
import ResourceApi from "@/api/resourceApi";
import Vue from "vue";

const itemApi = new ResourceApi("items");
const inventoryWorkApi = new ResourceApi("inventory-works");

export default {
  components: { ModalComponent, AddItemComponent },
  data() {
    return {
      loading: false,
      inventoryWorkId: this.$route.params.id,
      inventoryItems: [],
      itemIds: [],
      fields: InventoryItems.formFields(),
    };
  },

  created() {
    if (this.inventoryWorkId) {
      this.getItems();
    }
  },

  methods: {
    getItems() {
      this.loading = true;
      inventoryWorkApi
        .show({ id: this.inventoryWorkId })
        .then((response) => {
          response.data.inventory_items.map((invItem) => {
            const item = this.setItemDetails(invItem.item);

            this.inventoryItems.push(item);
            this.itemIds.push(item.id);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async onDecode(result) {
      return await itemApi
        .show({ id: result })
        .then((response) => {
          if (this.itemIds.includes(response.data.id)) {
            return {
              message: "アイテムはすでに存在します。",
              type: "error",
            };
          }
          this.addItemToTable(response.data);
          return {
            message: "テーブルにアイテムを追加します。",
            type: "success",
          };
        })
        .catch(() => {
          return {
            message: "アイテムが見つかりません。",
            type: "error",
          };
        });
    },

    handleDelete(item) {
      this.inventoryItems = this.inventoryItems.filter((i) => i.id !== item.id);
      this.itemIds = this.itemIds.filter((id) => id !== item.id);
    },

    addItemToTable(data) {
      const item = this.setItemDetails(data);
      this.inventoryItems.unshift(item);
      this.itemIds.unshift(item.id);
    },

    setItemDetails(item) {
      return {
        id: item.id ?? "",
        item_code: item.item_master?.item_code,
        shelf: item.shelf?.name ?? "",
        name: item.item_master?.name ?? "",
        size: item.item_master?.size.name ?? "",
        color: item.item_master ? item.item_master.color.color_code + ':' + item.item_master.color.name : "",
        cost: numberWithCommas(item.item_master?.cost ?? 0),
        price: item.stock?.price ? numberWithCommas(item.stock?.price ?? 0) : numberWithCommas(item.item_master?.price ?? 0),
        retail_price: numberWithCommas(item.item_master?.retail_price ?? 0),
      };
    },

    submitCreateForm() {
      this.$emit("submitCreateForm", { itemIds: this.itemIds });
    },

    submitUpdateForm() {
      const params = {
        id: this.inventoryWorkId,
        items: this.itemIds,
      };

      inventoryWorkApi
        .edit(params)
        .then(() => {
          Vue.$toast.success("登録成功しました。");
          setTimeout(this.$router.back(), 2000);
        })
        .catch(() => {
          Vue.$toast.error("少なくとも1つの製品が必要です");
        });
    },

    confirmSubmitForm() {
      if (!this.itemIds.length) {
        Vue.$toast.error("少なくとも1つの製品が必要です");
        return;
      }

      this.$confirm({
        title: "確認",
        message: "こちらの内容で登録をしてもよろしいですか？",
        button: {
          no: "いいえ",
          yes: "はい",
        },
        callback: (confirm) => {
          if (confirm) {
            this.inventoryWorkId
              ? this.submitUpdateForm()
              : this.submitCreateForm();
          }
        },
      });
    },

    goBack() {
      this.inventoryWorkId ? this.$router.go(-1) : this.$emit("goBack");
    },

    onRegisterItem(itemId) {
      itemApi
        .show({ id: itemId })
        .then((response) => {
          if (this.itemIds.includes(response.data.id)) {
            Vue.$toast.error("製品はすでに登録されています");
            return;
          }
          this.addItemToTable(response.data);
        })
        .catch(() => {
          Vue.$toast.error("該当商品がありません");
        });
    },
  },
};
</script>
