var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AddItemComponent',{attrs:{"title":"棚卸し商品登録","confirmSubmitForm":_vm.confirmSubmitForm,"goBack":_vm.goBack,"onDecode":_vm.onDecode,"onRegisterItem":_vm.onRegisterItem},scopedSlots:_vm._u([{key:"dataTable",fn:function(){return [_c('div',[_vm._v("登録数: "+_vm._s(_vm.itemIds.length))]),_c('div',[_vm._v("販売価格合計: "+_vm._s(_vm.inventoryItems.reduce(function (sum, item) { return sum + parseInt(item.price.replace(/,/g, '')); }, 0).toLocaleString()))]),_c('CDataTable',{attrs:{"hover":"","striped":"","border":"","itemsPerPage":200,"items":_vm.inventoryItems,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cost",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.cost)+" ")])}},{key:"price",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.price)+" ")])}},{key:"retail_price",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.retail_price)+" ")])}},{key:"delete",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CButtonClose',{on:{"click":function($event){return _vm.handleDelete(item)}}})],1)}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }